import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchResultsPage from './SearchResultsPage';
import LandingPage from './LandingPage'; // Move LandingPage to its own component

function App() {

    const [searchQuery, setSearchQuery] = useState(''); // This stores the submitted search query

    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={<LandingPage setQuery={setSearchQuery} />}
                />
                <Route path="/results" element={<SearchResultsPage query={searchQuery} setQuery={setSearchQuery}/>} />
            </Routes>
        </Router>
    );
}

export default App;